<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <div class="row">
      <div class="col">
        <b-card>
          <h2>Sample Test Instructions</h2>
          <hr />
          <div class="text-right">
            <button class="btn btn-sm btn-outline-primary" @click="$router.push({name:'sampletest-question', params:{book_id}})" title="Start the sample test now">Start Sample Test</button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import getBookMixin from '@/mixins/getbook';

export default {
  name: 'SampleTestInstruction',
  mixins: [getBookMixin],
  components: { BreadCrumb },

  data() {
    return {
      book_id: this.$route.params.book_id,
      book: null,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books Categories', to: { name: 'book-cat-list' } },
        { text: (this.book ? this.book.title : this.book_id), to: { name: 'book-preview', params: { book_id: this.book_id } } },
        { text: 'Book Test', to: { name: 'test-info', params: { book_id: this.book_id } } },
        { text: 'Sample Test', active: true },
      ];
    },
  },
};
</script>
